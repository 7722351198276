









































































































































































































































































































































































































import VueBase from '@/VueBase'
import { Component } from 'vue-property-decorator'
import Sortable from 'sortablejs'
@Component({ name: 'SysInfo' })
export default class SysInfo extends VueBase {
  private radio2 = '性能指标'
  private enumObj: any = {}
  private enumObjArr: any = {}
  mounted() {
    this.rowDrop()
  }
  //行拖拽
  rowDrop() {
    const tbody: any = document.querySelector('.el-table__body-wrapper tbody')
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this: any = this
    Sortable.create(tbody, {
      async onEnd({ newIndex, oldIndex }: any) {
        const currRow = _this.jsons.splice(oldIndex, 1)[0]
        _this.jsons.splice(newIndex, 0, currRow)
        _this.loadingStart()
        if (newIndex === 0) {
          const res = await _this.services.setting.circuitPriority(
            _this.jsons[newIndex],
            {
              type: 1,
            }
          )
          if (res.status !== 201) {
            _this.$message.error(res.msg)
            return
          }
          _this.$message.success(res.msg)
          _this.get_threshold()
        } else {
          if (newIndex === _this.jsons.length - 1) {
            const res = await _this.services.setting.circuitPriority(
              _this.jsons[newIndex],
              {
                type: 3,
              }
            )
            if (res.status !== 201) {
              _this.$message.error(res.msg)
              return
            }
            _this.$message.success(res.msg)
            _this.get_threshold()
          }
          if (newIndex !== _this.jsons.length - 1) {
            const res = await _this.services.setting.circuitPriority(
              _this.jsons[newIndex],
              {
                type: 2,
                priority_range: [
                  _this.jsons[newIndex - 1].priority,
                  _this.jsons[newIndex + 1].priority,
                ],
              }
            )
            if (res.status !== 201) {
              _this.$message.error(res.msg)
              return
            }
            _this.$message.success(res.msg)
            _this.get_threshold()
          }
        }

        _this.loadingDone()
      },
    })
  }

  metric_type_group = {
    1: 'system_',
    2: 'jvm_',
    3: 'application_',
  }

  unit_dict = {}
  async top(row: any) {
    const res = await this.services.setting.circuitPriority(row, { type: 1 })
    if (res.status !== 201) {
      this.$message.error(res.msg)
      return
    }
    this.$message.success(res.msg)
    this.get_threshold()
  }

  async reset(row: any) {
    const res = await this.services.setting.circuitReset(row)
    if (res.status !== 201) {
      this.$message.error(res.msg)
      return
    }
    this.$message.success(res.msg)
    this.get_threshold()
  }

  async changeEnable(row: any) {
    const res = await this.services.setting.updateCircuit(row)
    if (res.status !== 201) {
      this.$message.error(res.msg)
      return
    }
    this.$message.success(res.msg)
    this.get_threshold()
  }

  async saveDialog() {
    let res: any
    if (this.createFrom.id) {
      res = await this.services.setting.updateCircuit(this.createFrom)
    } else {
      res = await this.services.setting.createCircuit(this.createFrom)
    }
    if (res.status !== 201) {
      this.$message.error(res.msg)
      return
    }
    this.$message.success(res.msg)
    this.get_threshold()
    this.closeDialog()
  }

  private closeDialog() {
    this.createFrom = {
      name: '',
      metric_group: 1,
      deal: 1,
      is_enable: 1,
      targets: [],
      metrics: [],
    }
    this.agentConfigFlag = false
  }

  private createFrom: any = {
    name: '',
    metric_group: 1,
    deal: 1,
    is_enable: 1,
    targets: [],
    metrics: [],
  }

  addArr(arr: any, item: any) {
    arr.push(item)
  }

  removeArr(arr: any, index: any) {
    arr.splice(index, 1)
  }

  goBack() {
    this.$router.push({
      name: 'agentManage',
    })
  }
  public activeName = 'global'
  public form = {
    cpu_limit: '',
    auto_audit: '',
  }
  onSubmit() {
    console.log('确定')
  }

  get userInfo() {
    return this.$store.getters.userInfo
  }
  async getInfo() {
    const keys = []
    for (let key in this.form) {
      keys.push(key)
    }
    const res = await this.services.setting.profileGet({
      keys,
    })
    if (res.status === 201) {
      res.data.forEach((item: any) => {
        this.form[item.key] = item.value
      })
    } else {
      this.$message.error(res.msg)
    }
  }

  async profileModified() {
    const data = []
    for (let key in this.form) {
      data.push({
        value: String(this.form[key]),
        key,
      })
    }
    const res = await this.services.setting.profileModified(data)
    if (res.status === 201) {
      this.$message.success(res.msg)
    } else {
      this.$message.error(res.msg)
    }
  }

  private jsons: any = []
  private async get_threshold() {
    const res = await this.services.setting.get_threshold()
    if (res.status === 201) {
      this.jsons = res.data
    }
  }

  private async edit(row: any) {
    // this.$router.push({ name: 'agentConfig', query: { id: row.id } })
  }

  private async del(row: any) {
    this.$confirm(this.$t('views.agentConfig.confirmDel') as string, '', {
      confirmButtonText: this.$t('views.agentConfig.confirm') as string,
      cancelButtonText: this.$t('views.agentConfig.cancel') as string,
      type: 'warning',
    }).then(async () => {
      const res = await this.services.setting.del_threshold({ id: row.id })
      if (res.status === 201) {
        this.$message.success(res.msg)
        this.get_threshold()
        return
      }
      this.$message.error(res.msg)
    })
  }

  private agentConfigFlag = false

  async add(row: any) {
    if (row) {
      const res = await this.services.setting.getCircuit(row.id)
      if (res.status != 201) {
        this.$message.error(res.msg)
        return
      }
      this.createFrom = res.data
      this.agentConfigFlag = true
    } else {
      this.agentConfigFlag = true
    }
    // this.$router.push({ name: 'agentConfig' })
  }

  async created() {
    this.get_threshold()
    this.getInfo()
    const res = await this.services.setting.enum()
    this.enumObj = res.data

    for (let k in this.enumObj) {
      this.enumObjArr[k] = []
      for (let kk in this.enumObj[k]) {
        this.enumObjArr[k].push({
          name: this.enumObj[k][kk].name,
          value: Number(kk),
          label: this.enumObj[k][kk].label,
        })
      }
    }

    this.unit_dict = this.enumObj['UNIT_DICT']

    console.log(this.enumObjArr)

    for (let k in this.enumObj.metric_group) {
      this.createFrom.metric_group = k
      return
    }
  }
}
